import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { PhoneNumber } from '../../components/PhoneNumber';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';
import { useFindEmail } from '../../queries/user';
import { FindEmailRequestBody } from '../../types/user';

export const FindUserNamePage = () => {
  const [body, setBody] = useState<FindEmailRequestBody>({
    name: '',
    phone: '',
  });
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FindEmailRequestBody>();
  const [findEmail, setFindEmail] = useState(false);
  const onVerifiedPhoneNumber = (phone: string) => {
    setValue('phone', phone);
  };
  const { email } = useFindEmail(body);
  const onClickNext = (data: FindEmailRequestBody) => {
    setBody(data);
  };
  useEffect(() => {
    if (email) {
      setFindEmail(true);
    }
  }, [email]);
  return (
    <>
      <div className="mx-auto w-full max-w-md md:my-12">
        <TopBar title="아이디 찾기" />

        {findEmail ? (
          <div className="space-y-9 px-5">
            <h6 className="text-gray-700">회원님의 아이디입니다.</h6>

            <TextField peerStyle={false} disabled value={email} />

            <div className="pt-4">
              <Button
                to="/login"
                text="로그인하기"
                className="filled-gray-900 w-full"
              />
            </div>
          </div>
        ) : (
          <>
            <h6 className="px-5 text-gray-700">
              아이디 찾기를 위해 휴대폰 인증을 해주세요.
            </h6>

            <form className="mt-10 space-y-9 px-5 ">
              <TextField
                label="이름을 입력해주세요."
                placeholder="이름을 입력해주세요."
                helper={errors.name?.message}
                {...register('name', { required: '이름을 입력해주세요' })}
              />

              <PhoneNumber onVerified={onVerifiedPhoneNumber} />

              <div className="space-y-4 pt-2">
                <Button
                  text="다음"
                  onClick={handleSubmit(onClickNext)}
                  disabled={!isValid}
                  className="filled-gray-900 w-full"
                />

                <div className="grid place-content-center">
                  <Link to="/passwords/find" className=" text-15 text-gray-600">
                    비밀번호를 잊으셨나요?
                  </Link>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
};
