import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { PhoneNumber } from '../../components/PhoneNumber';
import { TextField } from '../../components/TextField';
import { GetUserIdRequestParams } from '../../types/user';
import { get } from 'http';

interface GetUserIdSectionProps {
  onSubmit: (data: GetUserIdRequestParams) => void;
}

export const GetUserIdSection = ({ onSubmit }: GetUserIdSectionProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm<GetUserIdRequestParams>({ mode: 'onChange' });

  const onVerifiedPhoneNumber = (phone: string) => {
    setValue('phone', phone, { shouldValidate: true });
  };

  useEffect(() => {
    if (!register) return;
    register('phone', { required: true });
  }, [register]);

  return (
    <form className="space-y-9 px-5">
      <h6 className="text-gray-700">
        비밀번호 변경을 위해 휴대폰 인증을 해주세요.
      </h6>

      <TextField
        label="이메일"
        placeholder="이메일"
        type="email"
        helper={errors.name?.message}
        {...register('email', { required: '이메일을 입력해주세요' })}
      />
      <TextField
        label="이름"
        placeholder="이름"
        helper={errors.name?.message}
        {...register('name', { required: '이름을 입력해주세요' })}
      />

      <PhoneNumber onVerified={onVerifiedPhoneNumber} />

      <div className="flex flex-col space-y-4 pt-4">
        <Button
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          text="다음"
          className="filled-gray-900"
        />

        <div className="grid place-content-center">
          <Link to="/username/find" className=" text-15 text-gray-600">
            아이디를 잊으셨나요?
          </Link>
        </div>
      </div>
    </form>
  );
};
