import React, { useState } from 'react';
import { TopBar } from '../../components/TopBar';
import { useGetUserId } from '../../queries/user';
import { GetUserIdRequestParams } from '../../types/user';
import { GetUserIdSection } from './GetUserIdSection';
import { ResetPasswordSection } from './ResetPasswordSection';

export const FindPasswordPage = () => {
  const [params, setParams] = useState({} as GetUserIdRequestParams);
  const { userId, token } = useGetUserId(params);

  return (
    <>
      <div className="mx-auto w-full max-w-md md:my-12">
        <TopBar title="비밀번호 변경" />
        {userId ? (
          <ResetPasswordSection userId={userId} token={token} />
        ) : (
          <GetUserIdSection onSubmit={(data) => setParams(data)} />
        )}
      </div>
    </>
  );
};
