import axios, { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import API from '../api';
import {
  ChangePasswordRequestBody,
  FindEmailRequestBody,
  GetUserIdRequestParams,
  GetUserIdResponse,
  SignUpBody,
  User,
} from '../types/user';
import { CouponDto, UsersCouponDto } from '../types/coupons';
import { useAuth } from '../hooks';
export const useUser = () => {
  const { authenticated } = useAuth();
  const { data, isLoading } = useQuery(
    'user',
    async () => await API.getUser(),
    {
      refetchOnMount: 'always',
      enabled: authenticated,
    }
  );
  if (isLoading || !data?.data) {
    return {
      user: {} as User,
    };
  }

  return {
    user: data.data,
  };
};

export const useCoupon = () => {
  const { data, isLoading } = useQuery(
    'coupons',
    async () => await API.getCoupons(),
    { refetchOnMount: 'always' }
  );
  if (isLoading || !data?.data) {
    return {
      coupons: [] as UsersCouponDto[],
    };
  }
  return {
    coupons: data.data.items,
  };
};

export const useUpdateUser = () => {
  const { push } = useHistory();
  const { mutate: updateUser } = useMutation(
    (data: Partial<SignUpBody>) => API.patchUser(data),
    {
      onSuccess: () => {
        push('/mypage');
        toast.success('내 정보 수정이 완료되었습니다.');
      },
    }
  );
  return {
    updateUser,
  };
};

export const useDeleteUser = () => {
  const { push } = useHistory();
  const { mutate: deleteUser } = useMutation(() => API.deleteUser(), {
    onSuccess: () => {
      push('/');
      toast.success('성공적으로 계정이 삭제되었습니다.');
    },
  });
  return {
    deleteUser,
  };
};

export const useFindEmail = (body: FindEmailRequestBody) => {
  const { data } = useQuery(
    ['userFindEmail', JSON.stringify(body)],
    async () => await API.postUserFindEmail(body),
    {
      enabled: !!body.name && !!body.phone,
      onError: (error) => {
        if (!axios.isAxiosError(error)) return;
        toast.error(error?.response?.data?.message);
      },
    }
  );
  return {
    email: data?.data ?? '',
  };
};

export const useGetUserId = (params: GetUserIdRequestParams) => {
  const { data, isLoading } = useQuery(
    ['getUserId', JSON.stringify(params)],
    async () => await API.getUserId(params),
    {
      enabled: !!params.email && !!params.name && !!params.phone,
      onError: (error) => {
        if (!axios.isAxiosError(error)) return;
        toast.error(error?.response?.data?.message);
      },
    }
  );

  if (isLoading || !data?.data) {
    return {} as GetUserIdResponse;
  }

  return {
    ...data?.data,
  };
};

export const useChangePassword = (id: number) => {
  const { push } = useHistory();
  const { mutate: changePassword } = useMutation(
    (body: ChangePasswordRequestBody) => API.patchUserPassword(id, body),
    {
      onSuccess: () => {
        push('/login');
        toast.success('비밀번호가 변경 되었습니다.');
      },
    }
  );
  return {
    changePassword,
  };
};
